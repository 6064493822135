import axios from "./axios.js"

// const token = "1|ku7lIj2vaSsk0iYkGbytMxN5FCu6sdojX6fM2fG0";


export class Users {

    static getUsers() {
        return axios.get("/users");

    }
    static getUser(userId) {
        let defaultURL = `/users/${userId}`;
        return axios.get(defaultURL);
    }
    static createUser(user) {
        let defaultURL = `/register`;
        // return axios.post(defaultURL,user);
        try {
            return axios.post(defaultURL, user);
        } catch (error) {
            console.log("this is error ", error)
            if (error.response.status === 422) {
                return error.response.data.errors

            }
            // console.log(errors.value.name);
        }
    }
    static updateUser(user, userId) {
        let defaultURL = `/users/${userId}`;
        return axios.put(defaultURL, user);
    }
    static deleteUser(userId) {
        let defaultURL = `/users/${userId}`;
        return axios.delete(defaultURL);
    }

}