<template>
    <div class="container">
        <div class="row form-signin w-100 m-auto">

            <img class="profile-img"
                src="https://lh5.googleusercontent.com/-b0-k99FZlyE/AAAAAAAAAAI/AAAAAAAAAAA/eu7opA4byxI/photo.jpg?sz=120" />
            <h3>Register User</h3>
            <!-- <pre>{{ form }}</pre> -->
            <form @submit="onSubmit" class="state-from">
                <div class="row">

                    <div class="mb-3">
                        <label for="name" class="form-label">Full Name </label>
                        <input type="text" v-model="form.name" class="form-control" id="name" placeholder="From Name"
                            required>
                    </div>

                    <div class="mb-3">
                        <label for="email" class="form-label">email </label>
                        <input type="email" v-model="form.email" class="form-control" id="email" placeholder="email"
                            required>
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">User password </label>
                        <input type="password" v-model="form.password" class="form-control" id="password"
                            placeholder="*****" required>
                    </div>

                </div>


                <input type="submit" :value="btnText" class="btn  btn-primary" />
            </form>

        </div>
    </div>

    <div class="container">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>User Name</th>
                        <th>Email Id</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user.id">
                        <td>{{ user.id }}</td>
                        <td>{{ user.name }}</td>
                        <td>{{ user.email }}</td>
                        <td><button class="btn btn-success" @click="editdata(user.id)">Edit</button> <button
                                class="btn btn-danger" @click="deletedata(user.id)">Delete</button> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="container" v-if="loading">
        <div class="row">
            <div class="col">
                <Spinner />
            </div>
        </div>
    </div>
</template>
<script>

import { Users } from '../services/Users'
import Spinner from '../components/Spinner.vue';
export default {
    name: 'Regiter',
    components: {
        Spinner
    },
    data() {
        return {
            form: {
                id: '',
                name: '',
                email: '',
                password: '',


            },
            loading: false,
            user: [],
            errors: [],


            btnText: 'Save',
            errorMasage: null
        }
    },
    async created() {

        try {
            this.loading = true
            let response = await Users.getUsers();
            // console.log(response.data);
            this.users = response.data.data;
            this.loading = false
        }
        catch (error) {
            this.errorMasage = error;
            this.loading = false
        }
    }, methods: {
        async onSubmit(e) {
            e.preventDefault()
            if (!this.form.name) {
                alert('Please Enter Full name')
                return
            }



            // const response = await Country.createCountry(form);
            if (this.btnText === 'Save') {
                try {
                    this.loading = true;
                    const response = await Users.createUser(this.form);
                    console.log(response);
                    // if (response.data.status == 'success') {
                    //     alert('created');
                    // } else {
                    //     alert('error');
                    // }
                    this.form.name = "";
                    this.form.email = "";
                    this.form.password = "";
                    this.loading = false;
                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        console.log(error.response.data.errors.name);
                        this.errorMasage = error.response.data;
                    }
                    this.loading = false;
                    // console.log(errors.value.name);
                }
            } else {
                // alert('update');
                try {
                    this.loading = true;
                    const response = await Users.updateUser(this.form, this.form.id);
                    console.log(response);
                    // if (response.data.status == 'success') {
                    //     // alert('Updated');
                    // } else {
                    //     alert('error');
                    // }
                    this.form.name = "";
                    this.form.email = "";
                    this.form.password = "";
                    this.loading = false;
                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        console.log(error.response.data.errors.name);

                    }
                    this.loading = false;
                    // console.log(errors.value.name);
                }
                this.btnText = "Save";
            }
            this.loading = true
            let response = await Users.getUsers();
            // console.log(response.data);
            this.users = response.data.data;
            this.loading = false


        },
        async editdata(id) {
            try {


                // alert(id);
                this.loading = true;
                this.btnText = "Update";
                const response = await Users.getUser(id);
                this.form.id = id;
                this.form.name = response.data.name;
                this.form.email = response.data.email;
                // this.form.password = response.data.password;              
                // console.log(response.data.password);
                // this.btnText = "Save";
                this.loading = false;
            }
            catch (error) {
                // console.log("this is error ", error)
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);

                }
                this.loading = false;
                // console.log(errors.value.name);
            }
        },
        async deletedata(id) {
            alert(id);
            this.loading = true;
            const resDel = await Users.deleteUser(id)
            console.log(resDel);
            // this.loading = true
            let response = await Users.getUsers();
            // console.log(response.data);
            this.users = response.data.data;
            this.loading = false
        }
    },
}
</script>
<style scoped>
.main {
    padding-top: 10%;
}


.form-signin {
    max-width: 430px;
    padding: 15px;
}

.profile-img {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
</style>